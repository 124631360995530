import { Injectable } from '@angular/core';
import { TeamHttpService } from './team-http.service';
import { Observable } from 'rxjs';
import { TeamModel } from '../models/TeamModel';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(private teamHttpService: TeamHttpService) { }
  getTeamByGlobalId(globalTeamId: string): Observable<TeamModel> {
    return this.teamHttpService.getTeamByGlobalTeamId(globalTeamId);
   }
}
