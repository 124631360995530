import { Injectable } from '@angular/core';
import { HttpHelper } from 'src/app/shared/services/httpHelper';
import * as gameState from '../state';
import * as gameActions from '../state/game-list.actions';
import { Observable } from 'rxjs';
import { GameHttpService } from 'src/app/shared/services/game-http.service';
import { GameModel } from 'src/app/shared/models/GameModel';
import { Store, select } from '@ngrx/store';
 

@Injectable({
  providedIn: 'root'
})
export class GameListService {

constructor(private gameHttpService: GameHttpService, private store: Store<gameState.State>  ) { }

getGamesByGlobalTeamId(id: string): Observable<GameModel[]> {
    return this.gameHttpService.getGamesByGlobalTeamId(id);
 }  

 
}
