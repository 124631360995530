import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { filter, startWith, distinctUntilChanged } from 'rxjs/operators';
import * as sharedState from '../state/shared.reducer';
import * as sharedStateActions from '../state/shared.actions';
import * as sharedStateSelectors from '../state/index';
import { UserModel } from '../models/userModel';
 


@Injectable({
  providedIn: 'root'
})
export class SharedStateService {
    constructor(private store: Store<sharedState.State>) {
    }

   
     public toastState$ = this.store.pipe(select(sharedStateSelectors.getToastState));

     public isSpinnerShowing$ =  this.store.pipe(select(sharedStateSelectors.isSpinnerShowing));

    public requireUser$ = this.store.pipe(
                                      select(sharedStateSelectors.getUser),
                                      filter(user => !user)
);


public user$: Observable<UserModel> = combineLatest(
                                          this.requireUser$.pipe(startWith(null)),
                                          this.store.pipe(select(sharedStateSelectors.getUser)),
                                          (a, b) => b
                                        ).pipe(distinctUntilChanged());
 
	 
    public userSignedIn = (user: UserModel): void => {
      this.store.dispatch(new sharedStateActions.UserLoggedIn(user));
    }


}
