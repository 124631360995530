import { Injectable } from '@angular/core';
import { GameResultsHttpService } from 'src/app/shared/services/game-results-http.service';
import { ScoreModel } from 'src/app/shared/models/ScoreModel';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as gameResultsState from '../state';
import * as gameResultsActions from '../state/game-results.actions';

@Injectable({
  providedIn: 'root'
})
export class GameResultsService {

  constructor(private gameResultsHttpService: GameResultsHttpService, public store: Store<gameResultsState.State>) { }

  upsertScore(model: ScoreModel): void {
    if (model.scoreId < 1) {
      this.store.dispatch(new gameResultsActions.GameResultsAddStart(model));
    }
    else {
      this.store.dispatch(new gameResultsActions.GameResultsUpdateStart(model));
    }
  }

  deleteScore(id: number): void {
    this.store.dispatch(new gameResultsActions.GameResultsDeleteStart(id));
  }
}
