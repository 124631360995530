import { Component, OnInit } from '@angular/core';
import { SellerModalGridComponent } from '../components/seller-modal-grid/seller-modal-grid.component';
import { MatDialog } from '@angular/material';
import { Store, select } from '@ngrx/store';
import * as fromShared from '../../shared/state/shared.reducer';
import * as fromSharedIndex from '../../shared/state/index';
import { UserModel } from 'src/app/shared/models/userModel';
@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss']
})
export class SellerComponent implements OnInit {
  user: UserModel;
  constructor(public dialog: MatDialog,public store: Store<fromShared.State>) { }

  ngOnInit() {
    this.store.pipe(select(fromSharedIndex.getUser)).subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }

  openDialog() {
    this.openDialogRef();
  }
  openDialogRef(): void {
    const dialogRef = this.dialog.open(SellerModalGridComponent, {
      width: '1000px',
      data: { globalTeamId:  this.user.globalTeamId}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
