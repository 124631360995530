import { FactoryProvider } from '@angular/core';
import { Router } from '@angular/router';

import { OKTA_CONFIG, OktaAuthService } from '@okta/okta-angular';
import { AppSettingsService } from '../services/appSettings.service';

 
export function onAuthRequired(_oktaAuth: OktaAuthService, router: Router) {
  router.navigate(['/login']);
}

export function oktaConfigFactory(appSettingsService: AppSettingsService) {
  const config = appSettingsService.getSettings();

  const oktaConfig = {
    ...config.oktaConfiguration,
    onAuthRequired,
  };

  return oktaConfig;
}

export const OktaConfigProvider: FactoryProvider = {
  provide: OKTA_CONFIG,
  useFactory: oktaConfigFactory,
  deps: [AppSettingsService],
};

