import { Injectable } from '@angular/core';
import { ScoreModel } from 'src/app/shared/models/ScoreModel';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as sellerState from '../state';
import * as sellerActions from '../state/seller.actions';
import { SellerHttpService } from 'src/app/shared/services/seller-http.service';
import { SellerModel } from 'src/app/shared/models/SellerModel';

@Injectable({
  providedIn: 'root'
})
export class SellerService {

  constructor(private sellerHttpService:  SellerHttpService, public store: Store<sellerState.State>) { }

  upsertScore(model: SellerModel): void {
    if (model.sellerId < 1) {
      this.store.dispatch(new sellerActions.SellerAddStart(model));
    }
    else {
      this.store.dispatch(new sellerActions.SellerUpdateStart(model));
    }
  }

  deleteScore(id: number): void {
    this.store.dispatch(new sellerActions.SellerDeleteStart(id));
  }
}
