import { Component, OnInit, Input, Inject } from '@angular/core';

import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from 'src/app/shared/components/square-assignment-modal/square-assignment-modal.component';
import { ScoreModel } from 'src/app/shared/models/ScoreModel';
import { GameModel } from 'src/app/shared/models/GameModel';
import { ScoreTypes } from 'src/app/shared/constants';

@Component({
  selector: 'app-game-results-modal',
  templateUrl: './game-results-modal.component.html',
  styleUrls: ['./game-results-modal.component.scss']
})
export class GameResultsModalComponent implements OnInit {
  scoreTypes: any[];
  public GameResultsForm: FormGroup;
  selectedScoreType = '0';
  isDelete: boolean = false;
  constructor(public dialogRef: MatDialogRef<GameResultsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) { }

  ngOnInit() {
    this.isDelete = this.data && this.data.action && this.data.action === 'Delete';
    this.scoreTypes = ScoreTypes.Types;
    this.selectedScoreType = this.data && this.data.score && this.data.score.scoreType ? this.data.score.scoreType.code : '0';
    this.GameResultsForm = this.fb.group({
      scoreType: this.fb.control(this.selectedScoreType),
      xAxisTeamScore: this.fb.control(this.data && this.data.score ? this.data.score.xAxisTeamScore : ''),
      yAxisTeamScore: this.fb.control(this.data && this.data.score ? this.data.score.yAxisTeamScore : ''),
    });

    
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onClearClick(): void {
    this.GameResultsForm.reset();
  }

  onSaveClick(): void {
    const newScore: ScoreModel = {
      scoreId: this.data.score ? this.data.score.scoreId : 0,
      globalGameIdentifier: this.data.game.info.globalGameIdentifier,
      scoreTypeId: this.GameResultsForm.controls.scoreType.value ?  this.GameResultsForm.controls.scoreType.value : 0,
      xAxisTeamScore: this.GameResultsForm.controls.xAxisTeamScore.value ?  this.GameResultsForm.controls.xAxisTeamScore.value : 0,
      yAxisTeamScore: this.GameResultsForm.controls.yAxisTeamScore.value ? this.GameResultsForm.controls.yAxisTeamScore.value : 0,
      scoreType: this.GameResultsForm.controls.scoreType.value ? this.scoreTypes.filter(x => x.id === this.GameResultsForm.controls.scoreType.value)[0] : null
    };

    this.dialogRef.close({score: newScore, isDelete: this.isDelete});
  }


}



