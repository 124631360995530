import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 
import { Routes, RouterModule } from '@angular/router';
import { EditGameComponent } from './container/edit-game.component';
import { EditGameModalComponent } from './componets/edit-game-modal/edit-game-modal.component';
 
 
 


const routes: Routes = [
  {
    path: '',
    component: EditGameModalComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditGameRoutingModule { }
