import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { map, switchMap, catchError, tap, filter } from 'rxjs/operators';
import * as sellerActions from './seller.actions';
import { Action } from '@ngrx/store';
import { ScoreModel } from 'src/app/shared/models/ScoreModel';
import { SellerHttpService } from 'src/app/shared/services/seller-http.service';
import * as fromSquareActions from '../../squares/state/squares.actions';
import { SellerModel } from 'src/app/shared/models/SellerModel';
 

@Injectable()
export class SellerEffects {

  constructor(
    private actions$: Actions,
    private sellerHttpService: SellerHttpService) { }


  @Effect()
  loadSellerByTeamId$: Observable<Action> = this.actions$.pipe(
    ofType(sellerActions.SellerActionTypes.SellerLoadStart),
    map((action: sellerActions.SellerLoadStart) => action.payload),
    switchMap((teamId: string) =>
     this.sellerHttpService.getSellersByTeamId(teamId)
    .pipe(
        map(data => (new sellerActions.SellerLoadSuccess(data))),
        catchError(err => of(new sellerActions.SellerLoadFail(err)))
      )
     )
  );

  @Effect()
  addNewSeller: Observable<Action> = this.actions$.pipe(
    ofType(sellerActions.SellerActionTypes.SellerAddStart),
    map((action: sellerActions.SellerAddStart) => action.payload),
    switchMap((model: SellerModel) =>
     this.sellerHttpService.addSeller(model)
    .pipe(
        switchMap((data) => [
                  new sellerActions.SellerAddSuccess(data),
                 
                ]),
        catchError(err => of(new sellerActions.SellerAddFail(err)))
      )
     )
  );

  @Effect()
  updateSeller: Observable<Action> = this.actions$.pipe(
    ofType(sellerActions.SellerActionTypes.SellerUpdateStart),
    map((action: sellerActions.SellerUpdateStart) => action.payload),
    switchMap((model: SellerModel) =>
     this.sellerHttpService.updateSeller(model)
    .pipe(
        switchMap((data) => [
             new sellerActions.SellerUpdateSuccess(data),
             new sellerActions.SellerLoadStart(data.globalTeamIdentifier)
          ]),
        catchError(err => of(new sellerActions.SellerUpdateFail(err)))
      )
     )
  );
  @Effect()
  deleteSeller: Observable<Action> = this.actions$.pipe(
    ofType(sellerActions.SellerActionTypes.SellerDeleteStart),
    map((action: sellerActions.SellerDeleteStart) => action.payload),
    switchMap((id: number) =>
     this.sellerHttpService.deleteSeller(id)
    .pipe(
        switchMap((data) => [
             new sellerActions.SellerDeleteSuccess(data),
             new sellerActions.SellerLoadStart(data.globalTeamIdentifier),
          ]),
        catchError(err => of(new sellerActions.SellerDeleteFail(err)))
      )
     )
  );
}
