import { Component, OnInit, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { saveAs } from 'file-saver';
import { GameService } from '../../services/game.service';
import html2canvas from 'html2canvas';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  @Input() showGameLinks: boolean = false;
  @Input() showMainLinks: boolean = false;
  @Input() showBackToGamesLink: boolean = false;
 

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private router: Router, private gameService: GameService) {
    iconRegistry.addSvgIcon(
      'menu',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/baseline-menu-24px.svg'));

    iconRegistry.addSvgIcon(
      'arrow_back',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/arrow_back-24px.svg'));

     
  }


  ngOnInit() {
    
  }



  gotoNewGame(id: number) {
    this.router.navigate([`/game/${id}`]);
  }

  exportToImage() {
    let fileName: string = '';
    this.gameService.selectedGame$.subscribe(game => {
      fileName = game.name.replace(/\s/g, '').concat('.png');
    });



    let element = document.getElementById('contentToConvert');
    html2canvas(element, { backgroundColor: '#fff', width: 1400 }).then(function (canvas) {
      // Convert the canvas to blob
      canvas.toBlob(function (blob) {
        saveAs(blob, fileName);
      }, 'image/png');
    });
  }
}
