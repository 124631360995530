import { Injectable } from '@angular/core';
import { HttpHelper } from './httpHelper';
import { Observable } from 'rxjs';
import { AppSettingsService } from './appSettings.service';
import { SellerModel } from '../models/SellerModel';

@Injectable({
  providedIn: 'root'
})
export class SellerHttpService {

  baseApiUrl: string = '';
  constructor(private httpHelper: HttpHelper, private appSettingsService: AppSettingsService) {
    this.appSettingsService.settings.subscribe(setting => {
      if (setting) {
        this.baseApiUrl = setting.baseApiUrl;
      }
    });
  }

  addSeller(model: SellerModel): Observable<SellerModel> {
    return this.httpHelper.post<SellerModel>(`${this.baseApiUrl}api/seller/`, model);
  }
  updateSeller(model: SellerModel): Observable<SellerModel> {
    return this.httpHelper.put<SellerModel>(`${this.baseApiUrl}api/seller/`, model);
  }
  getSellersByTeamId(teamId: string): Observable<SellerModel[]> {
    return this.httpHelper.get<SellerModel[]>(`${this.baseApiUrl}api/seller/getByTeamId/${teamId}`);
  }

deleteSeller(id: number): Observable<SellerModel> {
    return this.httpHelper.delete<SellerModel>(`${this.baseApiUrl}api/seller/${id}`);
  }
}
