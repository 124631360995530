import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as sharedActions from './shared.actions';
import { map, withLatestFrom, switchMap, filter, catchError, tap } from 'rxjs/operators';
import { SharedStateService } from '../services/shared-state.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ToastsComponent } from '../components/toasts/toasts.component';
import { ToastState } from '../state/shared.reducer';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import * as fromShared from './shared.actions';
import * as gameActions from '../../game/state/game.actions';
import * as gameConfigActions from '../../game-configuration/state/game-configuration.actions';
@Injectable()
export class SharedEffects {



  constructor(
    private actions$: Actions,
    private sharedStateService: SharedStateService,
    private snackBar: MatSnackBar,
  ) { }

 
  @Effect({ dispatch: false })
  ToastStateChanged$ = this.actions$.pipe(
    ofType(
      sharedActions.SharedActionTypes.SetToastError,
      sharedActions.SharedActionTypes.SetToastWarning,
      sharedActions.SharedActionTypes.SetToastConfirmation,
      sharedActions.SharedActionTypes.ClearToasts
    ),
    withLatestFrom(this.sharedStateService.toastState$),
    map((
      [action, toastState]
    ) => {
      this.configureSnackbar(action, toastState);
    })
  );


  @Effect()
  appLoadingStart$: Observable<Action> = this.actions$.pipe(
    ofType(

    ),
    map(() => new fromShared.LoadingStart())
  );

  @Effect()
  appLoadingFinish$: Observable<Action> = this.actions$.pipe(
    ofType(),
    map(() => new fromShared.LoadingFinished())
  );

  @Effect()
  forceAppLoadingFinish$: Observable<Action> = this.actions$.pipe(
    ofType(),
    map(() => new fromShared.ForceLoadingFinished())
  );
  @Effect()
  NavigateOut$: Observable<Action> = this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    map((action) => new fromShared.ClearToasts())
  );


 
  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType(
          gameActions.GameActionTypes.GamesCreatedStart,
          gameActions.GameActionTypes.GamesLoadStart,
          gameConfigActions.GameConfigurationActionTypes.AutoGenerateNumbersStart,
          gameConfigActions.GameConfigurationActionTypes.RandomlyAssignSellersStart,
          ),
    map(() => new fromShared.SetSpinnerStart()));

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType(gameActions.GameActionTypes.GamesCreatedFail,
           gameActions.GameActionTypes.GamesCreatedSuccess,
           gameActions.GameActionTypes.GamesLoadSuccess,
           gameActions.GameActionTypes.GamesLoadFail,
           gameConfigActions.GameConfigurationActionTypes.AutoGenerateNumbersFail,
           gameConfigActions.GameConfigurationActionTypes.AutoGenerateNumbersSuccess,
           gameConfigActions.GameConfigurationActionTypes.RandomlyAssignSellersFail,
           gameConfigActions.GameConfigurationActionTypes.RandomlyAssignSellersSuccess ),
    map(() => new fromShared.SetSpinnerStop()));
 
  private configureSnackbar = (action: Action, toastState: ToastState) => {
    const snackbarOptions: MatSnackBarConfig = {
      verticalPosition: 'top'
    };

    if (action.type === sharedActions.SharedActionTypes.SetToastError) {
      this.snackBar.openFromComponent(ToastsComponent, {
        ...snackbarOptions,
        panelClass: 'error',
        data: {
          toastClass: 'error',
          messageText: toastState.error,
        },
        duration: 0
      });
    } else if (action.type === sharedActions.SharedActionTypes.SetToastWarning) {
      this.snackBar.openFromComponent(ToastsComponent, {
        ...snackbarOptions,
        panelClass: 'warning',
        data: {
          messageText: toastState.warning,
          toastClass: 'warning'
        },
        duration: this.getToastDuration(action as sharedActions.SetToastWarning, toastState.warning)
      });
    } else if (action.type === sharedActions.SharedActionTypes.SetToastConfirmation) {
      this.snackBar.openFromComponent(ToastsComponent, {
        ...snackbarOptions,
        panelClass: 'confirmation',
        data: {
          messageText: toastState.confirmation,
          toastClass: 'confirmation'
        },
        duration: this.getToastDuration(action as sharedActions.SetToastConfirmation, toastState.confirmation)
      });
    } else {
      this.snackBar.dismiss();
    }
  }

 

  private getToastDuration(action: sharedActions.SetToastConfirmation | sharedActions.SetToastWarning, content: string): number {
    if (action.payload.duration != null) {
      return action.payload.duration;
    }

    return content.indexOf('href') >= 0 ? 10000 : 4500;
  }
}
