import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaCallbackComponent, OktaAuthGuard } from '@okta/okta-angular';
 
 
 

 

const routes: Routes = [
  { path: 'implicit/callback', component:  OktaCallbackComponent },
   
  {
    path: 'games',
    loadChildren: './game-list/game-list.module#GameListModule',
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'squares/:id',
    loadChildren: './squares/squares.module#SquaresModule',
   canActivate: [OktaAuthGuard]
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
