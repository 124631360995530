import { Action } from '@ngrx/store';
import * as sellersActions from './seller.actions';
import * as fromRoot from '../../shared/state/shared.reducer';
import { SellerModel } from 'src/app/shared/models/SellerModel';
import * as fromShared from '../../shared/state/shared.reducer';
 

export interface State  {
  isLoading: boolean;
  error: string;
  sellers: SellerModel[];
}

export const initialState: State = {
  isLoading: false,
  error: '',
  sellers: null
};


export function reducer(state = initialState,
                        action: sellersActions.SellerActions): State {
  switch (action.type) {
    case sellersActions.SellerActionTypes.SellerAddStart:
      return {
        ...state, isLoading: true,
      };
    case sellersActions.SellerActionTypes.SellerAddSuccess:
      return {
        ...state,
        sellers: [action.payload, ...state.sellers],
        isLoading: false
      };
    case sellersActions.SellerActionTypes.SellerAddFail:
      return {
        ...state,
        error: action.payload, isLoading: false
      };
      case sellersActions.SellerActionTypes.SellerUpdateStart:
        return {
          ...state, isLoading: true,
        };
      case sellersActions.SellerActionTypes.SellerUpdateSuccess:
        const index =  state.sellers.map(score => score.sellerId).indexOf(action.payload.sellerId);
        return { ...state,
                 ...state.sellers[index] = action.payload,
            isLoading: false
        };
      case sellersActions.SellerActionTypes.SellerUpdateFail:
        return {
          ...state,
          error: action.payload, isLoading: false
        };
      case sellersActions.SellerActionTypes.SellerLoadStart:
      return {
        ...state, isLoading: true,
      };
    case sellersActions.SellerActionTypes.SellerLoadSuccess:
      return {
        ...state,
        sellers: action.payload,
        isLoading: false
      };
    case sellersActions.SellerActionTypes.SellerLoadFail:
      return {
        ...state,
        error: action.payload, isLoading: false
      };  
      case sellersActions.SellerActionTypes.SellerDeleteStart:
      return {
        ...state, isLoading: true
      };
    case sellersActions.SellerActionTypes.SellerDeleteSuccess:
      const idx =  state.sellers.map(seller => seller.sellerId).indexOf(action.payload.sellerId);
      return { ...state,
        ...state.sellers.slice(0, idx),
        ...state.sellers.slice(idx + 1),
          isLoading: false
      };
    case sellersActions.SellerActionTypes.SellerDeleteFail:
      return {
        ...state,
        error: action.payload, isLoading: false
      };
    default:
      return state;
  }
}
