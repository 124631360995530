import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { map, switchMap, catchError, tap, filter } from 'rxjs/operators';
import * as gameActions from './game.actions';
import { Action } from '@ngrx/store';
import * as gameListActions from '../../game-list/state/game-list.actions';
import { SetToastError, SetToastConfirmation, SetSpinnerStart, SetSpinnerStop } from '../../shared/state/shared.actions';
import { GameHttpService } from 'src/app/shared/services/game-http.service';

@Injectable()
export class GameEffects {

  constructor(
    private actions$: Actions,
    private gameHttpService: GameHttpService
  ) { }


  @Effect()
  CreateNewGames$: Observable<Action> = this.actions$.pipe(
    ofType(gameActions.GameActionTypes.GamesCreatedStart),
    switchMap((action: gameActions.GamesCreatedStart) => {
            return this.gameHttpService.newGame(action.payload).pipe(
        switchMap(data => ([
          new gameActions.GamesCreatedSuccess(data),
          new SetToastConfirmation({ content: `${data.name } has successfully been created!`, duration: 4000}),
          new gameListActions.GamesLoadStart(data.team.globalTeamIdentifier)
        ])),
        catchError(err => of(
          new gameActions.GamesCreatedFail(err),
          new SetToastError('Failed to create game!')))
  );
})
  );
  @Effect()
  editGame$: Observable<Action> = this.actions$.pipe(
    ofType(gameActions.GameActionTypes.GameEditStart),
    switchMap((action: gameActions.GameEditStart) => {
      return this.gameHttpService.editGame(action.payload).pipe(
        switchMap(data => ([
          new gameActions.GameEditSuccess(data),
          new SetToastConfirmation({ content: `${data.name } has successfully been updated!`, duration: 4000}),
          new gameListActions.GamesLoadStart(data.team.globalTeamIdentifier)
        ])),
        catchError(err => of(
          new gameActions.GameEditFail(err),
          new SetToastError('Failed to update game!')))
  );
})
  );

  
    }
