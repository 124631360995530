
import * as sharedActions from './shared.actions';
import { UserModel } from '../models/UserMode';
export interface State {
  user: UserModel;
  toasts: ToastState;
  isLoading: boolean;
  error: string;
  showSpinner: boolean;
}
export const initialState: State = {
  isLoading: false,
  error: '',
  user: undefined,
  toasts: {
    error: undefined,
    warning: undefined,
    confirmation: undefined
  },
  showSpinner: false
};

export interface ToastState {
  error: string;
  warning: string;
  confirmation: string;
}
export function reducer(
  state = initialState,
  action: sharedActions.SharedActions): State {
  switch (action.type) {
    case sharedActions.SharedActionTypes.LoadingStart:
      return { ...state, isLoading: true };
    case sharedActions.SharedActionTypes.LoadingFinished:
      return { ...state, isLoading: false };
    case sharedActions.SharedActionTypes.UserLoggedIn:
      return {
        ...state,
        user: {
          ...state.user,
          name: action.payload.name,
          email: action.payload.email,
          initials: action.payload.initials,
          globalTeamId: action.payload.globalTeamId
        }
      };
    case sharedActions.SharedActionTypes.SetToastError:
      return {
        ...state,
        toasts: {
          ...state.toasts,
          error: action.payload
        }
      };
    case sharedActions.SharedActionTypes.SetToastWarning:
      return {
        ...state,
        toasts: {
          ...state.toasts,
          warning: action.payload.content
        }
      };
    case sharedActions.SharedActionTypes.SetToastConfirmation:
      return {
        ...state,
        toasts: {
          ...state.toasts,
          confirmation: action.payload.content
        }
      };
    case sharedActions.SharedActionTypes.ClearToasts:
      return {
        ...state,
        toasts: initialState.toasts
      };
    case sharedActions.SharedActionTypes.SetSpinnerStop:
      return { ...state, showSpinner: false };
    case sharedActions.SharedActionTypes.SetSpinnerStart:
      return { ...state, showSpinner: true };
    default:
      return state;
  }


}
export const isShowing = (state: State) => state.showSpinner;
