import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store, select } from '@ngrx/store';
import { GameResultsModalComponent } from '../components/game-results-modal/game-results-modal.component';
import * as squareState from '../../squares/state/squares.reducer';
import * as fromSquareIndex from '../../squares/state/index';
import { GameModel } from 'src/app/shared/models/GameModel';
import { GameResultsModalGridComponent } from '../components/game-results-modal-grid/game-results-modal-grid.component';

@Component({
  selector: 'app-game-results',
  templateUrl: './game-results.component.html',
  styleUrls: ['./game-results.component.scss']
})
export class GameResultsComponent implements OnInit {
  game: GameModel;
  constructor(public dialog: MatDialog, public store: Store<squareState.State>) { }

  ngOnInit() {
    this.store.pipe(select(fromSquareIndex.getSelectedGame)).subscribe(game => {
      if (game) {
        this.game = game;
      }
    });
  }


  openDialog() {
    this.openDialogRef();
  }
  openDialogRef(): void {
    const dialogRef = this.dialog.open(GameResultsModalGridComponent, {
      width: '600px',
      data: { info: this.game }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}