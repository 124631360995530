import { Component, OnInit } from '@angular/core';
import { SharedStateService } from '../../services/shared-state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-page-spinner',
  templateUrl: './page-spinner.component.html',
  styleUrls: ['./page-spinner.component.scss']
})
export class PageSpinnerComponent implements OnInit {

  constructor(private sharedStateService: SharedStateService) { }
  loading: Observable<boolean>;
  ngOnInit() {
  
    this.loading = this.sharedStateService.isSpinnerShowing$;

  }

}
