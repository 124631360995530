import { Injectable } from '@angular/core';
import { HttpHelper } from 'src/app/shared/services/httpHelper';

import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GameModel } from '../models/GameModel';
import { AppSettingsService } from './appSettings.service';

@Injectable({
  providedIn: 'root'
})
export class GameHttpService {
  baseApiUrl: string = '';
  constructor(private httpHelper: HttpHelper, private appSettingsService: AppSettingsService) {
    this.appSettingsService.settings.subscribe(setting => {
      if (setting) {
        this.baseApiUrl = setting.baseApiUrl;
      }
    });
  }

  getGames(): Observable<GameModel[]> {
    return this.httpHelper.get<GameModel[]>(`${this.baseApiUrl}api/Game`);
  }
  getGamesByGlobalTeamId(globalTeamId: string): Observable<GameModel[]> {
    return this.httpHelper.get<GameModel[]>(`${this.baseApiUrl}api/Game/getByGlobalTeamId/${globalTeamId}`);
  }
  newGame(model: GameModel): Observable<GameModel> {
    return this.httpHelper.post<GameModel>(`${this.baseApiUrl}api/Game`, model);
  }

  editGame(model: GameModel): Observable<GameModel> {
    return this.httpHelper.put<GameModel>(`${this.baseApiUrl}api/Game/`, model);
  }
  // getGame(gameId: Number): Observable<GameModel> {
  //   return this.httpHelper.get<GameModel>(${this.baseApiUrl}api/Game/getById/${gameId}`);
  // }
  getGameByGlobalGameId(globalGameId: string): Observable<GameModel> {
    return this.httpHelper.get<GameModel>(`${this.baseApiUrl}api/Game/getByGlobalGameId/${globalGameId}`);
  }

}
