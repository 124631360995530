import { Injectable } from '@angular/core';
import { HttpHelper } from 'src/app/shared/services/httpHelper';

import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GameModel } from '../models/GameModel';
import { CommonRequestModel } from '../models/CommonRequestModel';
import { RandomlyAssignSellersRequestModel } from '../models/RandomlyAssignSellersRequestModel';
import { AppSettingsService } from './appSettings.service';

@Injectable({
  providedIn: 'root'
})
export class GameConfigurationHttpService {

  baseApiUrl: string = '';
  constructor(private httpHelper: HttpHelper, private appSettingsService: AppSettingsService) {
    this.appSettingsService.settings.subscribe(setting => {
      if (setting) {
        this.baseApiUrl = setting.baseApiUrl;
      }
    });
  }

  generateRandomNumbers(model: CommonRequestModel): Observable<boolean> {
    return this.httpHelper.post<boolean>(`${this.baseApiUrl}api/GameConfiguration/GenerateRandomNumbers/`, model);

  }
 randomlyAssignSellers(model: RandomlyAssignSellersRequestModel): Observable<boolean> {
    return this.httpHelper.post<boolean>(`${this.baseApiUrl}api/GameConfiguration/RandomlyAssignSellers/`, model);

  }
}
