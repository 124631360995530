import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import {
  CommonModule
} from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SquareAssignmentModalComponent } from '../components/square-assignment-modal/square-assignment-modal.component';
import { GameDetailModalComponent } from '../components/game-detail-modal/game-detail-modal.component';
import { GameResultsComponent } from 'src/app/game-results/container/game-results.component';
import { GameResultsModalComponent } from 'src/app/game-results/components/game-results-modal/game-results-modal.component';
import { GameResultsModalGridComponent } from 'src/app/game-results/components/game-results-modal-grid/game-results-modal-grid.component';
import { GameResultsModule } from 'src/app/game-results/game-results.module';
import { ToastsComponent } from '../components/toasts/toasts.component';
import { FeatureNames } from '../constants';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from '../state/shared.reducer';
import { SharedEffects } from '../state/shared.effects';
import { PageSpinnerComponent } from '../components/page-spinner/page-spinner.component';
 

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    EffectsModule.forFeature([SharedEffects]),
  ],
  declarations: [
    SquareAssignmentModalComponent, GameDetailModalComponent, ToastsComponent, PageSpinnerComponent
  ],
  exports: [
    ReactiveFormsModule,
    ToastsComponent,
    PageSpinnerComponent
  ],
  providers: [
  ],
  entryComponents: [
   SquareAssignmentModalComponent, GameDetailModalComponent, ToastsComponent, PageSpinnerComponent
  ]
})
export class SharedModule {}
