import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSquare from './squares.reducer';
import { FeatureNames } from 'src/app/shared/constants';
export interface State   {
  SelectedGame: fromSquare.State;
  SquareDetails: fromSquare.State;
}

const getSelectedGameFeatureState = createFeatureSelector<fromSquare.State>(FeatureNames.Squares);

export const getSelectedGame = createSelector(
  getSelectedGameFeatureState,
  state => state != null ? state.selectedGame : undefined
);
export const getSquareDetails = createSelector(
  getSelectedGameFeatureState,
  state => state != null ? state.squareDetails : undefined
);

 