import { Action } from '@ngrx/store';
import * as gameResultsActions from './game-results.actions';
import * as fromRoot from '../../shared/state/shared.reducer';
import { ScoreModel } from 'src/app/shared/models/ScoreModel';

export const gameResultsFeatureKey = 'gameResults';

export interface State  {
  isLoading: boolean;
  error: string;
  scores: ScoreModel[];
}

export const initialState: State = {
  isLoading: false,
  error: '',
  scores: null
};


export function reducer(state = initialState,
                        action: gameResultsActions.GameResultsActions): State {
  switch (action.type) {
    case gameResultsActions.GameResultsActionTypes.GameResultsAddStart:
      return {
        ...state, isLoading: true,
      };
    case gameResultsActions.GameResultsActionTypes.GameResultsAddSuccess:
      return {
        ...state,
        scores: [action.payload, ...state.scores],
        isLoading: false
      };
    case gameResultsActions.GameResultsActionTypes.GameResultsAddFail:
      return {
        ...state,
        error: action.payload, isLoading: false
      };
      case gameResultsActions.GameResultsActionTypes.GameResultsUpdateStart:
        return {
          ...state, isLoading: true,
        };
      case gameResultsActions.GameResultsActionTypes.GameResultsUpdateSuccess:
        const index =  state.scores.map(score => score.scoreId).indexOf(action.payload.scoreId);
        return { ...state,
                 ...state.scores[index] = action.payload,
            isLoading: false
        };
      case gameResultsActions.GameResultsActionTypes.GameResultsUpdateFail:
        return {
          ...state,
          error: action.payload, isLoading: false
        };
      case gameResultsActions.GameResultsActionTypes.GameResultsLoadStart:
      return {
        ...state, isLoading: true,
      };
    case gameResultsActions.GameResultsActionTypes.GameResultsLoadSuccess:
      return {
        ...state,
        scores: action.payload,
        isLoading: false
      };
    case gameResultsActions.GameResultsActionTypes.GameResultsLoadFail:
      return {
        ...state,
        error: action.payload, isLoading: false
      };  
      case gameResultsActions.GameResultsActionTypes.GameResultsDeleteStart:
      return {
        ...state, isLoading: true
      };
    case gameResultsActions.GameResultsActionTypes.GameResultsDeleteSuccess:
      const idx =  state.scores.map(score => score.scoreId).indexOf(action.payload.scoreId);
      return { ...state,
        ...state.scores.slice(0, idx),
        ...state.scores.slice(idx + 1),
          isLoading: false
      };
    case gameResultsActions.GameResultsActionTypes.GameResultsDeleteFail:
      return {
        ...state,
        error: action.payload, isLoading: false
      };
    default:
      return state;
  }
}
