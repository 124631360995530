import { Action } from '@ngrx/store';
import { GameModel } from 'src/app/shared/models/GameModel';
 


export enum GameActionTypes {
  GamesCreatedStart = '[Game] Games Created Start',
  GamesCreatedSuccess = '[Game] Games Created Success',
  GamesCreatedFail = '[Game] Games Created Fail',
  GameEditStart = '[Game] Game Edit Start',
  GameEditSuccess = '[Game] Game Edit Success',
  GameEditFail = '[Game] Game Edit Fail',
  GamesLoadStart = '[Game] Games Load Start',
  GamesLoadSuccess = '[Game] Games Load Success',
  GamesLoadFail = '[Game] Games Load Fail',

}
export class GamesCreatedStart implements Action {
  readonly type = GameActionTypes.GamesCreatedStart;
  constructor(public payload: GameModel) { }
}
export class GamesCreatedSuccess implements Action {
  readonly type = GameActionTypes.GamesCreatedSuccess;

  constructor(public payload: GameModel) { }
}

export class GamesCreatedFail implements Action {
  readonly type = GameActionTypes.GamesCreatedFail;

  constructor(public payload: string) { }
}
export class GameEditStart implements Action {
  readonly type = GameActionTypes.GameEditStart;
  constructor(public payload: GameModel) { }
}
export class GameEditSuccess implements Action {
  readonly type = GameActionTypes.GameEditSuccess;

  constructor(public payload: GameModel) { }
}

export class GameEditFail implements Action {
  readonly type = GameActionTypes.GameEditFail;

  constructor(public payload: string) { }
}

export type GameActions =  GamesCreatedStart | GamesCreatedSuccess | GamesCreatedFail
                              |GameEditStart | GameEditSuccess | GameEditFail;

 
