import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MaterialModule } from '../shared/modules/material.module';
import { GameRoutingModule } from './game-routing.module';
import { GameEffects } from './state/game.effects';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NewGameComponent } from './container/new-game.component';
import { GameService } from '../shared/services/game.service';

@NgModule({
  imports: [
    CommonModule,
    GameRoutingModule,
    MaterialModule,
    EffectsModule.forFeature([GameEffects])
    ],
  exports: [NewGameComponent],
  providers: [GameService],
  declarations: [NewGameComponent],

})
export class GameModule { }
