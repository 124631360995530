import { Component, OnInit, Input } from '@angular/core';
import { GameModel } from 'src/app/shared/models/GameModel';
import { MatDialog } from '@angular/material';


import { GameDetailModalComponent } from 'src/app/shared/components/game-detail-modal/game-detail-modal.component';
import { GameService } from 'src/app/shared/services/game.service';




@Component({
  selector: 'app-new-game',
  templateUrl: './new-game.component.html',
  styleUrls: ['./new-game.component.scss']
})

export class NewGameComponent implements OnInit {
  constructor(public dialog: MatDialog, private gameService: GameService) { }
  game: GameModel;

  ngOnInit() {

  }
  openDialog() {
    this.openDialogRef();
  }
  openDialogRef(): void {
    const dialogRef = this.dialog.open(GameDetailModalComponent, {
      width: '500px',
      data: null
    });
    dialogRef.afterClosed().subscribe((result) => {
      const g: GameModel = {
        name: result.name,
        description: result.description,
        xAxisName: result.xAxisName,
        yAxisName: result.yAxisName,
        showNumbers: result.showNumbers,
        numberOfSquares: 100,
        numbers: result.numbers,
        team: result.team,
        numberOfAvailableSquares: result.numberOfAvailableSquares,
        globalGameIdentifier: result.globalGameIdentifier,
        numberOfTeamSquares: result.numberOfTeamSquares
      };
      this.game = g;

      this.gameService.CreateNewGame(this.game);

    });


  }

}
