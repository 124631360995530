import { Injectable } from '@angular/core';
import { HttpHelper } from './httpHelper';
import { ScoreModel } from '../models/ScoreModel';
import { Observable } from 'rxjs';
import { AppSettingsService } from './appSettings.service';

@Injectable({
  providedIn: 'root'
})
export class GameResultsHttpService {

  baseApiUrl: string = '';
  constructor(private httpHelper: HttpHelper, private appSettingsService: AppSettingsService) {
    this.appSettingsService.settings.subscribe(setting => {
      if (setting) {
        this.baseApiUrl = setting.baseApiUrl;
      }
    });
  }

  newScore(model: ScoreModel): Observable<ScoreModel> {
    return this.httpHelper.post<ScoreModel>(`${this.baseApiUrl}api/score/`, model);
  }
  updateScore(model: ScoreModel): Observable<ScoreModel> {
    return this.httpHelper.put<ScoreModel>(`${this.baseApiUrl}api/score/`, model);
  }
  getScoresByGameId(gameId: string): Observable<ScoreModel[]> {
    return this.httpHelper.get<ScoreModel[]>(`${this.baseApiUrl}api/score/getByGameId/${gameId}`);
  }

deleteScore(id: number): Observable<ScoreModel> {
    return this.httpHelper.delete<ScoreModel>(`${this.baseApiUrl}api/score/${id}`);
  }
}
