import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, Injector, Inject } from '@angular/core';

import { of } from 'rxjs';

import { AppSettingsService, IAppSettings } from '../services/appSettings.service';

 



@Injectable()
export class PreInitService {

  baseUrl: string;
  constructor(
    private httpBackend: HttpBackend,
    private appSettingsService: AppSettingsService,
   ) {
     

  }

  getSettings(): Promise<boolean> {
    const http = new HttpClient(this.httpBackend);
    return http.get<IAppSettings>(`/api/settings/appsettings`).toPromise()
    .then(config => this.appSettingsService.setSettings(config))
    .then(_ => true)
    .catch(error => {
      console.error('Error loading application configuration', error);
      return Promise.resolve(false);
    });

    /* return of(APP_SETTINGS)
      .toPromise()
      .then(config => this.appSettingsService.setSettings(config))
      .then(() => console.log('app-settings.json loaded'))
      .then(_ => true)
      .catch(error => {
        console.error('Error loading application configuration', error);
        return Promise.resolve(false);
      }); */
  }

}
