import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State } from '../../game-list/state/game-list.reducer';
import * as fromAction from '../../game-list/state/game-list.actions';
import * as fromIndex from '../../game-list/state/index';
import * as gameState from '../state';
import { Observable } from 'rxjs';
import { GameModel } from 'src/app/shared/models/GameModel';
import { OktaAuthService } from '@okta/okta-angular';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { UserInfoService } from 'src/app/shared/services/user-info.service';

@Component({
  selector: 'app-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.scss']
})
export class GameListComponent implements OnInit {
  gameList$: GameModel[];

  constructor(private store: Store<gameState.State>, public sharedStateService: SharedStateService, private userInfoService: UserInfoService) { }

  ngOnInit() {
 
    this.sharedStateService.user$.subscribe((user) => {
      if (user) {
        this.store.dispatch(new fromAction.GamesLoadStart(user.globalTeamId));

        this.store.pipe(select(fromIndex.getGamesList)).subscribe(games => {
          if (games) {
            this.gameList$ = games;
          }
        });
      }
    });
  }

}
