import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { GameService } from 'src/app/shared/services/game.service';
import { GameDetailModalComponent } from 'src/app/shared/components/game-detail-modal/game-detail-modal.component';
import { GameModel } from 'src/app/shared/models/GameModel';
import { EditGameModalComponent } from '../componets/edit-game-modal/edit-game-modal.component';

@Component({
  selector: 'app-edit-game',
  templateUrl: './edit-game.component.html',
  styleUrls: ['./edit-game.component.scss']
})
export class EditGameComponent implements OnInit {

  constructor(public dialog: MatDialog, private gameService: GameService) { }
  @Input() game: GameModel;
  ngOnInit() {
  }


  openDialog() {
    this.openDialogRef();
  }
  openDialogRef(): void {
    const dialogRef = this.dialog.open(EditGameModalComponent, {
      width: '500px',
      data: {
        globalGameIdentifier: this.game.globalGameIdentifier, name: this.game.name, description: this.game.description,
        showNumbers: this.game.showNumbers, xAxisName: this.game.xAxisName,
        yAxisName: this.game.yAxisName, numberOfTeamSquares: this.game.numberOfTeamSquares
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      const g: GameModel = {
        name: result.name,
        description: result.description,
        xAxisName: result.xAxisName,
        yAxisName: result.yAxisName,
        showNumbers: result.showNumbers,
        numberOfSquares: 100,
        numbers: result.numbers,
        team: result.team,
        numberOfAvailableSquares: result.numberOfAvailableSquares,
        globalGameIdentifier: result.globalGameIdentifier,
        numberOfTeamSquares: result.numberOfTeamSquares
      };
      this.game = g;
      this.gameService.EditGame(this.game);


    });

  }
}
