import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGameList from './game-list.reducer';
import { FeatureNames } from 'src/app/shared/constants';

export interface State   {
  Games: fromGameList.State;
}

const getGameListFeatureState = createFeatureSelector<fromGameList.State>(FeatureNames.Games);

export const getGamesList = createSelector(
  getGameListFeatureState,
  state => state != null ? state.games : undefined
);
