import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckboxChange } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TeamService } from 'src/app/shared/services/team.service';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { TeamModel } from 'src/app/shared/models/TeamModel';
import { GameModel } from 'src/app/shared/models/GameModel';

@Component({
  selector: 'app-edit-game-modal',
  templateUrl: './edit-game-modal.component.html',
  styleUrls: ['./edit-game-modal.component.scss']
})
export class EditGameModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditGameModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditGameDialogData, private fb: FormBuilder, private teamService: TeamService
    , public sharedStateService: SharedStateService) { }
    team: TeamModel;
    public EditGameForm: FormGroup;
    name: string = '';
    description: string = '';
    xAxisName: string = '';
    yAxisName: string = '';
    showNumbers: boolean = false;
    globalGameIdentifier: string;
    numberOfTeamSquares: number;
  ngOnInit() {

    if (this.data) {
      this.name = this.data.name;
      this.description = this.data.description;
      this.xAxisName = this.data.xAxisName;
      this.yAxisName = this.data.yAxisName;
      this.showNumbers = this.data.showNumbers;
      this.globalGameIdentifier = this.data.globalGameIdentifier;
      this.numberOfTeamSquares = this.data.numberOfTeamSquares;
    }
    this.sharedStateService.user$.subscribe((user) => {
      if (user) {
        this.teamService.getTeamByGlobalId(user.globalTeamId).subscribe(team => {
          this.team = team;
        });
      }
    });
    
    this.EditGameForm = this.fb.group({
      name: this.fb.control(this.name),
      description: this.fb.control(this.description),
      xAxisName: this.fb.control(this.xAxisName),
      yAxisName: this.fb.control(this.yAxisName),
      showNumbers: this.fb.control(this.showNumbers),
      numberOfTeamSquares: this.fb.control(this.numberOfTeamSquares)
    });
  
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  onAddNewClick(): void {
    const game: GameModel = {
      name: this.EditGameForm.controls.name.value,
      description: this.EditGameForm.controls.description.value,
      xAxisName: this.EditGameForm.controls.xAxisName.value,
      yAxisName: this.EditGameForm.controls.yAxisName.value,
      showNumbers: this.EditGameForm.controls.showNumbers.value,
      numberOfSquares: 100,
   
      numbers: [],
      team: this.team,
      numberOfAvailableSquares: 100,
      globalGameIdentifier: this.data.globalGameIdentifier,
      numberOfTeamSquares: this.EditGameForm.controls.numberOfTeamSquares.value
    };

   
    this.dialogRef.close(game);
  }
  showNumbersChanged = (matCheckboxChange: MatCheckboxChange) => {
    this.data.showNumbers = matCheckboxChange.checked;
  }
}
export class EditGameDialogData {
  name: string;
  description: string;
  xAxisName: string;
  yAxisName: string;
  showNumbers: boolean;
  globalGameIdentifier: string;
  numberOfTeamSquares: number;
}
