import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GameModel } from '../models/GameModel';
import { GameHttpService } from './game-http.service';
import { ActivatedRoute } from '@angular/router';
 
import { CommonRequestModel } from '../models/CommonRequestModel';
import * as newGameActions from '../../game/state/game.actions';
import * as gamesState from '../../game-list/state/game-list.reducer';
import * as gameConfigActions from '../../game-configuration/state/game-configuration.actions';
import { Store, select } from '@ngrx/store';
import { RandomlyAssignSellersRequestModel } from '../models/RandomlyAssignSellersRequestModel';
import * as fromSquareIndex from '../../squares/state/index';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(private gameHttpService: GameHttpService, private route: ActivatedRoute,
              private store: Store<gamesState.State> ) { }

  //  getGame(gameId: Number): Observable<GameModel> {
  //   return this.gameHttpService.getGame(gameId);
  //  }
  getGameByGlobalId(globalGameId: string): Observable<GameModel> {
    return this.gameHttpService.getGameByGlobalGameId(globalGameId);
  }
  getGameIdFromRoute(): string {
    return this.route.snapshot.paramMap.get('id');
  }

  generateRandomNumbers(globalGameId: string): void {
    const model: CommonRequestModel = { globalGameIdentifier: globalGameId };
    this.store.dispatch(new gameConfigActions.AutoGenerateNumbersStart(model));
  }
  randomlyAssignSellers(globalGameId: string, globalTeamId: string): void {
    const model: RandomlyAssignSellersRequestModel = { globalGameIdentifier: globalGameId, globalTeamIdentifier: globalTeamId };
    this.store.dispatch(new gameConfigActions.RandomlyAssignSellersStart(model));
  }

  CreateNewGame(model: GameModel) {
    this.store.dispatch(new newGameActions.GamesCreatedStart(model));
  }
  
  EditGame(model: GameModel) {
    this.store.dispatch(new newGameActions.GameEditStart(model));
   }

   public selectedGame$: Observable<GameModel> = this.store.pipe(select(fromSquareIndex.getSelectedGame));
}
