export class FeatureNames {
    public static readonly Main: string = 'main';
    public static readonly GameResults: string = 'game-results';
    public static readonly Games: string = 'game-info';
    public static readonly Squares: string = 'square-details';
    public static readonly Shared: string = 'shared';
    public static readonly Sellers: string = 'seller-detail';
  }

export class ScoreTypes {
public static readonly Types = [
  {
  id: 1,
  code: '1',
  name: 'First Quarter Score',
  description: 'First Quarter Score'
  },
  {
   id: 2,
  code: '2',
  name: 'Halftime Score',
  description: 'Halftime Score'
  },
  {
   id: 3,
  code: '3',
  name: 'Third Quarter Score',
  description: 'Third Quarter Score',
  },
  {
   id: 4,
  code: '4',
  name: 'Final Score',
  description: 'Final Score'
  },
];

}

export const APP_SETTINGS = {
  "oktaConfiguration": {
    "baseUrl": "https://your-domain.oktapreview.com",
    "clientId": "<your-clientid>",
    "issuer": "https://your-domain.oktapreview.com/oauth2/<your-auth-service-id>",
    "redirectUri": "http://localhost:4200/implicit/callback",
  },
  "baseApiAddress": ""
}