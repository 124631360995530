import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SellerModel } from 'src/app/shared/models/SellerModel';

@Component({
  selector: 'app-seller-modal',
  templateUrl: './seller-modal.component.html',
  styleUrls: ['./seller-modal.component.scss']
})
export class SellerModalComponent implements OnInit {
  isDelete: boolean;
  isEdit: boolean;
  isInsert: boolean;
  public SellerForm: FormGroup;
  currentSeller: SellerModel;
  constructor(public dialogRef: MatDialogRef<SellerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) { }

  ngOnInit() {
    this.isDelete = this.data && this.data.action && this.data.action === 'Delete';
    if(this.data && this.data.seller){
      this.currentSeller = this.data.seller;
    }
    this.SellerForm = this.fb.group({
      firstName: this.fb.control(this.data && this.data.seller ? this.data.seller.firstName : ''),
      lastName: this.fb.control(this.data && this.data.seller ? this.data.seller.lastName : ''),
      emailAddress: this.fb.control(this.data && this.data.seller ? this.data.seller.emailAddress : ''),
      isTeamSeller: this.fb.control(this.data && this.data.seller ? this.data.seller.isTeamSeller : false),
      isActive: this.fb.control(this.data && this.data.seller ? this.data.seller.isActive : false),
    });

  }
  onCancelClick(): void {
    this.dialogRef.close();
  }

  onClearClick(): void {
    this.SellerForm.reset();
  }

  onSaveClick(): void {
    const newSeller: SellerModel = {
      sellerId: this.data.seller ? this.data.seller.sellerId : 0,
      globalTeamIdentifier: this.data.info.globalTeamId,
      firstName: this.SellerForm.controls.firstName.value ?  this.SellerForm.controls.firstName.value : '',
      lastName: this.SellerForm.controls.lastName.value ?  this.SellerForm.controls.lastName.value : '',
      emailAddress: this.SellerForm.controls.emailAddress.value ? this.SellerForm.controls.emailAddress.value : '',
      isTeamSeller: this.SellerForm.controls.isTeamSeller.value,
      isActive: this.SellerForm.controls.isActive.value,
    };

    this.dialogRef.close({seller: newSeller, isDelete: this.isDelete});
  }
}