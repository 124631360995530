import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { MaterialModule } from '../shared/modules/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GameConfigurationEffects } from './state/game-configuration.effects';

 

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    EffectsModule.forFeature([GameConfigurationEffects]),
  ],
  declarations: [
  ],
  exports: [
  ]
})
export class GameConfigurationModule { }
