import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckboxChange } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SquareDetailModel } from 'src/app/squares/models/squareDetailModel';

@Component({
  selector: 'app-square-assignment-modal',
  templateUrl: './square-assignment-modal.component.html',
  styleUrls: ['./square-assignment-modal.component.scss']
})

export class SquareAssignmentModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SquareAssignmentModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData, private fb: FormBuilder) { }
  public squareAssignmentForm: FormGroup;
  squareLocation: string;
  ngOnInit() {
    this.squareAssignmentForm  = this.fb.group({
      ownerFirstName: this.fb.control(this.data.detail.owner.firstName),
      ownerLastName: this.fb.control(this.data.detail.owner.lastName),
      ownerEmail: this.fb.control(this.data.detail.owner.emailAddress),
      hasPaid: this.fb.control(this.data.detail.hasPaid)
    });
    this.squareLocation = (this.data.detail.number.xAxisIndex + ' , ' + this.data.detail.number.yAxisIndex).toString();
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }

  onClearClick(): void {
    this.squareAssignmentForm.reset();
  }

  onSaveClick(): void {
     const newAssignment: SquareDetailModel = {
      location: [this.data.detail.number.xAxisIndex, this.data.detail.number.yAxisIndex],
      owner: {
        ownerId: 0,
        firstName: this.squareAssignmentForm.controls.ownerFirstName.value,
        lastName: this.squareAssignmentForm.controls.ownerLastName.value,
        emailAddress: this.squareAssignmentForm.controls.ownerEmail.value
        },
      seller: this.data.detail.seller,
      game: this.data.detail.game,
      number: this.data.detail.number,
      hasPaid: this.squareAssignmentForm.controls.hasPaid.value ?  this.squareAssignmentForm.controls.hasPaid.value : false,
      winners: this.data.detail.winners
    };

     this.dialogRef.close(newAssignment);
  }
  hasPaidCheckboxChanged = (matCheckboxChange: MatCheckboxChange) => {
    this.data.detail.hasPaid = matCheckboxChange.checked;
  }
}
export class DialogData {
  detail: SquareDetailModel;
}

