import { Action } from '@ngrx/store';
import { GameModel } from 'src/app/shared/models/GameModel';
import { CommonRequestModel } from 'src/app/shared/models/CommonRequestModel';
import { RandomlyAssignSellersRequestModel } from 'src/app/shared/models/RandomlyAssignSellersRequestModel';
 


export enum GameConfigurationActionTypes {
 
  AutoGenerateNumbersStart = '[Game Configuration] Auto Generate Numbers Start',
  AutoGenerateNumbersSuccess = '[Game Configuration] Auto Generate Numbers Success',
  AutoGenerateNumbersFail = '[Game Configuration] Auto Generate Numbers Fail',
  RandomlyAssignSellersStart = '[Game Configuration] Randomly Assign Sellers Start',
  RandomlyAssignSellersSuccess = '[Game Configuration] Randomly Assign Sellers Success',
  RandomlyAssignSellersFail = '[Game Configuration] Randomly Assign Sellers Fail',
}
 
export class  AutoGenerateNumbersStart implements Action {
  readonly type = GameConfigurationActionTypes.AutoGenerateNumbersStart;
  constructor(public payload: CommonRequestModel) { }
}
export class  AutoGenerateNumbersSuccess implements Action {
  readonly type = GameConfigurationActionTypes.AutoGenerateNumbersSuccess;

  constructor(public payload: boolean) { }
}

export class  AutoGenerateNumbersFail implements Action {
  readonly type = GameConfigurationActionTypes.AutoGenerateNumbersFail;

  constructor(public payload: string) { }
}
export class RandomlyAssignSellersStart implements Action {
  readonly type = GameConfigurationActionTypes. RandomlyAssignSellersStart;
  constructor(public payload: RandomlyAssignSellersRequestModel) { }
}
export class   RandomlyAssignSellersSuccess implements Action {
  readonly type = GameConfigurationActionTypes. RandomlyAssignSellersSuccess;

  constructor(public payload: boolean) { }
}

export class   RandomlyAssignSellersFail implements Action {
  readonly type = GameConfigurationActionTypes. RandomlyAssignSellersFail;

  constructor(public payload: string) { }
}
export type GameConfigurationActions = AutoGenerateNumbersStart | AutoGenerateNumbersSuccess | AutoGenerateNumbersFail
                                       | RandomlyAssignSellersStart | RandomlyAssignSellersSuccess | RandomlyAssignSellersFail;

 
