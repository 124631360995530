import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { GameResultsModalComponent } from '../game-results-modal/game-results-modal.component';
import { GameModel } from 'src/app/shared/models/GameModel';
import { GameResultsService } from '../../services/game-results.service';
import { Store, select } from '@ngrx/store';
import * as resultsState from '../../state/game-results.reducer';
import * as fromIndex from '../../state/index';
import * as fromAction from '../../state/game-results.actions';
import { ScoreModel } from 'src/app/shared/models/ScoreModel';
import { ScoreTypes } from 'src/app/shared/constants';
 
@Component({
  selector: 'app-game-results-modal-grid',
  templateUrl: './game-results-modal-grid.component.html',
  styleUrls: ['./game-results-modal-grid.component.scss']
})
export class GameResultsModalGridComponent implements OnInit {
  scores: ScoreModel[];

  displayedColumns: string[] = ['scoreType', 'xAxisName', 'yAxisName', 'action'];
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<GameResultsModalGridComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public gameResultsService: GameResultsService, public store: Store<resultsState.State>) { }

  ngOnInit() {
   
    this.store.dispatch(new fromAction.GameResultsLoadStart(this.data.info.globalGameIdentifier));
    this.store.pipe(select(fromIndex.getGamesResults)).subscribe(scores => {
      if (scores) {
        this.scores = scores;
      }
    });
  }

  openDialog(action: string, score: ScoreModel) {
    this.openDialogRef(action, score);
  }
  openDialogRef(action: string, score: ScoreModel): void {
    const dialogRef = this.dialog.open(GameResultsModalComponent, {
      width: '500px',
      data: { game: this.data, score, action }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.isDelete){
        this.gameResultsService.deleteScore(result.score.scoreId);
      } else {
        this.gameResultsService.upsertScore(result.score);
       
      }
    });
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
  
}
