import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGameResults from './game-results.reducer';
import { FeatureNames } from 'src/app/shared/constants';

export interface State   {
  scores: fromGameResults.State;
}

const getGamesResultsFeatureState = createFeatureSelector<fromGameResults.State>(FeatureNames.GameResults);

export const getGamesResults = createSelector(
  getGamesResultsFeatureState,
  state => state != null ? state.scores ? state.scores
  .sort((a, b) => a.scoreTypeId < b.scoreTypeId ? -1 : a.scoreTypeId > b.scoreTypeId ? 1 : 0) : undefined : null);
 