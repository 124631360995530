import { Action } from '@ngrx/store';
import { UserModel } from '../models/UserMode';
export enum SharedActionTypes {
  LoadingStart = '[Shared] Loading started',
  LoadingFinished = '[Shared] Loading finished',
  ForceLoadingFinished = '[Shared] Forcing loading finished',
  UserLoggedIn = '[Shared] User Logged In',
  SetToastError = '[Shared] Set Toast Error',
  SetToastWarning = '[Shared] Set Toast Warning',
  SetToastConfirmation = '[Shared] Set Toast Confirmation',
  ClearToasts = '[Shared] Clear Toasts',
  SetSpinnerStart =  '[Shared] Start Spinner',
  SetSpinnerStop = '[Shared] Stop Spinner'
}
export interface SetToastPayload {
  content: string;
  duration: number;
}
export class LoadingStart implements Action {
  readonly type = SharedActionTypes.LoadingStart;
}
export class LoadingFinished implements Action {
  readonly type = SharedActionTypes.LoadingFinished;
}
export class ForceLoadingFinished implements Action {
  readonly type = SharedActionTypes.ForceLoadingFinished;
}
export class UserLoggedIn implements Action {
  readonly type = SharedActionTypes.UserLoggedIn;

  constructor(public payload: UserModel) { }
}
export class SetToastError implements Action {
  readonly type = SharedActionTypes.SetToastError;

  constructor(public payload: string) { }
}

export class SetToastWarning implements Action {
  readonly type = SharedActionTypes.SetToastWarning;

  constructor(public payload: SetToastPayload) { }
}

export class SetToastConfirmation implements Action {
  readonly type = SharedActionTypes.SetToastConfirmation;

  constructor(public payload: SetToastPayload) { }
}

export class ClearToasts implements Action {
  readonly type = SharedActionTypes.ClearToasts;
}

export class SetSpinnerStart implements Action {
  readonly type = SharedActionTypes.SetSpinnerStart;

  constructor() { }
}

export class SetSpinnerStop implements Action {
  readonly type = SharedActionTypes.SetSpinnerStop;

  constructor() { }
}
 


export type SharedActions =
  LoadingStart
  | LoadingFinished
  | ForceLoadingFinished
  | UserLoggedIn
  | SetToastError
  | SetToastWarning
  | SetToastConfirmation
  | ClearToasts
  | SetSpinnerStart
  | SetSpinnerStop;
