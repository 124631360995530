import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MaterialModule } from '../shared/modules/material.module';
 
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
 
import { GameService } from '../shared/services/game.service';
 
import { EditGameRoutingModule } from './edit-game-routing.module';
import { EditGameModalComponent } from './componets/edit-game-modal/edit-game-modal.component';

@NgModule({
  imports: [
    CommonModule,
    EditGameRoutingModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    
    ],
  exports: [],
  providers: [GameService],
  declarations: [EditGameModalComponent],
  entryComponents: [
    EditGameModalComponent
   ]

})
export class EditGameModule { }
