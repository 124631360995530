import { Action } from '@ngrx/store';
import { GameModel } from 'src/app/shared/models/GameModel';
import { SquareDetailModel } from '../models/squareDetailModel';
 


export enum SquaresActionTypes {
 
  GameSelectedStart = '[Square] Game Selected Start',
  GameSelectedSuccess = '[Square] Game Selected Success',
  GameSelectedFail = '[Square] Game Selected Fail',
  LoadSquareDetailsStart = '[Square] Load Square Details Start',
  LoadSquareDetailsSuccess = '[Square] Load Square Details Success',
  LoadSquareDetailsFail = '[Square] Load Square Details Fail'

}
 
export class GameSelectedStart implements Action {
  readonly type = SquaresActionTypes.GameSelectedStart;
  constructor(public payload: string) { }
}
export class GameSelectedSuccess implements Action {
  readonly type = SquaresActionTypes.GameSelectedSuccess;

  constructor(public payload: GameModel) { }
}

export class GameSelectedFail implements Action {
  readonly type = SquaresActionTypes.GameSelectedFail;

  constructor(public payload: string) { }
}
export class LoadSquareDetailsStart implements Action {
  readonly type = SquaresActionTypes.LoadSquareDetailsStart;
  constructor(public payload: string) { }
}
export class LoadSquareDetailsSuccess implements Action {
  readonly type = SquaresActionTypes.LoadSquareDetailsSuccess;

  constructor(public payload: SquareDetailModel[]) { }
}

export class  LoadSquareDetailsFail implements Action {
  readonly type = SquaresActionTypes.LoadSquareDetailsFail;

  constructor(public payload: string) { }
}
 
export type SquaresActions = GameSelectedStart | GameSelectedSuccess
| GameSelectedFail | LoadSquareDetailsStart | LoadSquareDetailsSuccess| LoadSquareDetailsFail;

 
