import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { map, switchMap, catchError, tap, filter } from 'rxjs/operators';
import * as gameListActions from './game-list.actions';
import { Action } from '@ngrx/store';
import { GameListService } from '../services/game-list.service';
@Injectable()
export class GameListEffects {

  constructor(
    private actions$: Actions,
    private gameListService: GameListService
  ) { }


  @Effect()
  loadGames$: Observable<Action> = this.actions$.pipe(
    ofType(gameListActions.GameListActionTypes.GamesLoadStart),
    switchMap((action: gameListActions.GamesLoadStart) => {
         return this.gameListService.getGamesByGlobalTeamId(action.payload).pipe(
        map(data => (new gameListActions.GamesLoadSuccess(data))),
        catchError(err => of(new gameListActions.GamesLoadFail(err)))
      );
      })
  );
    }
