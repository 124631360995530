import { Action } from '@ngrx/store';
import { SellerModel } from 'src/app/shared/models/SellerModel';

export enum SellerActionTypes {
  SellerLoadStart = '[Seller] Seller Load Start',
  SellerLoadSuccess = '[Seller] Seller Load Success',
  SellerLoadFail = '[Seller] Seller Load Fail',
  SellerAddStart = '[Seller] Seller Add Start',
  SellerAddSuccess = '[Seller] Seller Add Success',
  SellerAddFail = '[Seller] Seller Add Fail',
  SellerUpdateStart = '[Seller] Seller Update Start',
  SellerUpdateSuccess = '[Seller] Seller Update Success',
  SellerUpdateFail = '[Seller] Seller Update Fail',
  SellerDeleteStart = '[Seller] Seller Delete Start',
  SellerDeleteSuccess = '[Seller] Seller Delete Success',
  SellerDeleteFail = '[Seller] Seller Delete Fail',
}
 
export class SellerLoadStart implements Action {
  readonly type = SellerActionTypes.SellerLoadStart;
  constructor(public payload: string) { }
}
export class SellerLoadSuccess implements Action {
  readonly type = SellerActionTypes.SellerLoadSuccess;

  constructor(public payload: SellerModel[]) { }
}

export class SellerLoadFail implements Action {
  readonly type = SellerActionTypes.SellerLoadFail;

  constructor(public payload: string) { }
}
export class SellerAddStart implements Action {
  readonly type = SellerActionTypes.SellerAddStart;
  constructor(public payload: SellerModel) { }
}
export class SellerAddSuccess implements Action {
  readonly type = SellerActionTypes.SellerAddSuccess;
  constructor(public payload: SellerModel) { }
}

export class SellerAddFail implements Action {
  readonly type = SellerActionTypes.SellerAddFail;

  constructor(public payload: string) { }
}
export class SellerUpdateStart implements Action {
  readonly type = SellerActionTypes.SellerUpdateStart;
  constructor(public payload: SellerModel) { }
}
export class SellerUpdateSuccess implements Action {
  readonly type = SellerActionTypes.SellerUpdateSuccess;

  constructor(public payload: SellerModel) { }
}

export class SellerUpdateFail implements Action {
  readonly type = SellerActionTypes.SellerUpdateFail;

  constructor(public payload: string) { }
}
export class SellerDeleteStart implements Action {
  readonly type = SellerActionTypes.SellerDeleteStart;
  constructor(public payload: number) { }
}
export class SellerDeleteSuccess implements Action {
  readonly type = SellerActionTypes.SellerDeleteSuccess;

  constructor(public payload: SellerModel) { }
}

export class SellerDeleteFail implements Action {
  readonly type = SellerActionTypes.SellerDeleteFail;

  constructor(public payload: string) { }
}
export type SellerActions = SellerLoadStart | SellerLoadSuccess | SellerLoadFail
                             | SellerAddStart | SellerAddSuccess | SellerAddFail
                              | SellerUpdateStart | SellerUpdateSuccess | SellerUpdateFail
                              | SellerDeleteStart | SellerDeleteSuccess | SellerDeleteFail;
