import { Component, OnInit, AfterContentInit } from '@angular/core';

import { OktaAuthService, UserClaims } from '@okta/okta-angular';
import { UserModel } from './shared/models/UserMode';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { UserInfoService } from './shared/services/user-info.service';
import { filter, take, flatMap } from 'rxjs/operators';
import { SharedStateService } from './shared/services/shared-state.service';
import { Observable } from 'rxjs';
import { AppSettingsService } from './shared/services/appSettings.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit {

  title = 'Square Fundraising';
  isAuthenticated: boolean = false;
  isChrome = false;
  userFullName: string;
  user$: Observable<UserModel>;
  showGameLinks: boolean = false;
  showMainLinks: boolean = false;
  showBackToGamesLink: boolean = false;
  constructor(public oktaAuth: OktaAuthService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private sharedStateService: SharedStateService,
    private userInfoService: UserInfoService,
    private appSettingsService: AppSettingsService, 
    private router: Router) {

    iconRegistry.addSvgIcon(
      'arrow_down',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/arrow_down_24px.svg'));
    iconRegistry.addSvgIcon(
      'check-outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        './assets/img/checkbox-marked-circle-outline.svg'
      )
    );

    this.oktaAuth.$authenticationState.subscribe((isAuthenticated: boolean) => {
      this.isAuthenticated = isAuthenticated;
      this.getAuthInfo();

    });
    this.router.events.subscribe(
      (event) => {
         if (event instanceof NavigationEnd) {
          if (event.url.indexOf('/squares') > -1) {
            this.showGameLinks = true;
            this.showMainLinks = false;
            this.showBackToGamesLink = true;
          }
          if (event.url.indexOf('/games') > -1) {
            this.showGameLinks = false;
            this.showMainLinks = true;
            this.showBackToGamesLink = false;
          }
          }
      });
  }

  ngOnInit() {
    this.getAuthInfo();

    this.isChrome = true;
  }
  getAuthInfo() {
    this.oktaAuth.isAuthenticated().then((a) => {
      if (a) {
        this.isAuthenticated = true;
        this.userInfoService.userInfo.subscribe(user => {
          this.sharedStateService.userSignedIn(user);
         /*  if (this.appSettingsService.isLoaded) {
          } else {
            this.appSettingsService.loadConfig();
          } */
        });

        this.user$ = this.sharedStateService.user$;
 
      }
    });

  }
  login() {
    this.oktaAuth.loginRedirect('/games');
  }

  logout() {
    this.oktaAuth.logout('/');
  }

  ngAfterContentInit() {
  }
  private initialsFromName(name: string): string {
    if (name == null) {
      return '';
    }

    const nameTokens = name.split(' ');
    let fnInitial = '', lnInitial = '';
    if (nameTokens[0]) {
      fnInitial = nameTokens[0].slice(0, 1);
    }
    if (nameTokens[nameTokens.length - 1] && nameTokens.length > 1) {
      lnInitial = nameTokens[nameTokens.length - 1].slice(0, 1);
    }

    return `${fnInitial}${lnInitial}`;
  }


}
