import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent implements OnInit {
  toastClass: string;
  messageText: SafeHtml;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) private data,
    private sanitizer: DomSanitizer,
    private snackBarRef: MatSnackBarRef<ToastsComponent>
  ) { }

  ngOnInit() {
    this.toastClass = this.data.toastClass;
    this.messageText = this.sanitizer.bypassSecurityTrustHtml(this.data.messageText);
  }

  onCloseClicked = () => {
    this.snackBarRef.dismiss();
  }
}
