import { Injectable } from '@angular/core';
import { HttpHelper } from 'src/app/shared/services/httpHelper';
 
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SquareDetailModel } from 'src/app/squares/models/squareDetailModel';
import { AppSettingsService } from './appSettings.service';
 

@Injectable({
  providedIn: 'root'
})
export class SquareHttpService {

  baseApiUrl: string = '';
  constructor(private httpHelper: HttpHelper, private appSettingsService: AppSettingsService) {
    this.appSettingsService.settings.subscribe(setting => {
      if (setting) {
        this.baseApiUrl = setting.baseApiUrl;
      }
    });
  }

  getSquaresByGlobalGameId(globalGameId: string): Observable<SquareDetailModel[]> {
    return this.httpHelper.get<SquareDetailModel[]>(`${this.baseApiUrl}api/squareDetail/getByGameId/${globalGameId}`);
  }
  

  updateSquare(model: SquareDetailModel): Observable<SquareDetailModel> {
    return this.httpHelper.put<SquareDetailModel>(`${this.baseApiUrl}api/SquareDetail/`, model);
  }

  areSellersAssigned(gameId: string) {
    return this.httpHelper.get<boolean>(`${this.baseApiUrl}api/squareDetail/areSellersAssigned/${gameId}`);
  }
}
