/* import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { retry, tap, share, catchError } from 'rxjs/operators';
import { AppSettings } from '../models/appSettings';

@Injectable({
  providedIn: 'root'
})

export class AppSettingsService {
  private $settings: Observable<AppSettings>;
  baseUrl: string;
  private settings: AppSettings;
  isLoaded: boolean = false;
  baseApiAddress: string;
 
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl) {
    this.baseUrl = `${baseUrl}api/settings/appsettings/`;

    this.http.get<AppSettings>(`${this.baseUrl}`).pipe(
        retry(3),
        tap((response) => {
         
          this.settings = { ...response };
          this.baseApiAddress = response.baseApiAddress;
        })
      )
      .pipe(share()); 
  }

  loadConfig(): Promise<AppSettings> {
    if (this.settings) {
      return of(this.settings).toPromise();
    }

    return this.$settings.toPromise();
  }

}*/

import { Injectable } from '@angular/core';
 

import { Observable, BehaviorSubject } from 'rxjs';
import { OktaConfig } from '@okta/okta-angular/dist/src/okta/models/okta.config';
 


export interface IAppSettings {
  oktaConfiguration?: OktaConfig;
  baseApiUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  settings: Observable<Readonly<IAppSettings>>;

  private settingsSource: BehaviorSubject<IAppSettings> = new BehaviorSubject<
    IAppSettings
  >({
    baseApiUrl: '',
    oktaConfiguration: undefined
  });

  constructor() {
    this.settings = this.settingsSource.asObservable();
  }

  setSettings(settings: Partial<IAppSettings>) {
    const updatedConfig = { ...this.settingsSource.value, ...settings };

    this.settingsSource.next(updatedConfig);
  }

  getSettings(): Readonly<IAppSettings> {
    return this.settingsSource.value;
  }
}