import { Injectable } from '@angular/core';
import { HttpHelper } from './httpHelper';
import { Observable } from 'rxjs';
import { TeamModel } from '../models/TeamModel';
import { AppSettingsService } from './appSettings.service';

@Injectable({
  providedIn: 'root'
})
export class TeamHttpService {

  baseApiUrl: string = '';
  constructor(private httpHelper: HttpHelper, private appSettingsService: AppSettingsService) {
    this.appSettingsService.settings.subscribe(setting => {
      if (setting) {
        this.baseApiUrl = setting.baseApiUrl;
      }
    });
  }

  getTeamByGlobalTeamId(globalTeamId: string): Observable<TeamModel> {
    return this.httpHelper.get<TeamModel>(`${this.baseApiUrl}api/team/getByGlobalTeamId/${globalTeamId}`);
  }
}
