import { Component, OnInit, Input, Inject, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GameModel } from '../../models/GameModel';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckboxChange } from '@angular/material';
import { OktaAuthService } from '@okta/okta-angular';
import { TeamService } from '../../services/team.service';
import { TeamModel } from '../../models/TeamModel';
import { SharedStateService } from '../../services/shared-state.service';
import { OuterSubscriber } from 'rxjs/internal/OuterSubscriber';

@Component({
  selector: 'app-game-detail-modal',
  templateUrl: './game-detail-modal.component.html',
  styleUrls: ['./game-detail-modal.component.scss']
})
export class GameDetailModalComponent implements OnInit {
  team: TeamModel;
  public GameForm: FormGroup;
 
  constructor(public dialogRef: MatDialogRef<GameDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GameDetailDialogData, private fb: FormBuilder, private teamService: TeamService
    , public sharedStateService: SharedStateService) { }

  ngOnInit() {

     
    this.sharedStateService.user$.subscribe((user) => {
      if (user) {
        this.teamService.getTeamByGlobalId(user.globalTeamId).subscribe(team => {
          this.team = team;
        });
      }
    });


    this.GameForm = this.fb.group({
      name: this.fb.control(''),
      description: this.fb.control(''),
      xAxisName: this.fb.control(''),
      yAxisName: this.fb.control(''),
      showNumbers: this.fb.control(''), 
      numberOfTeamSquares: this.fb.control('')
    });
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  onAddNewClick(): void {
    const game: GameModel = {
      name: this.GameForm.controls.name.value,
      description: this.GameForm.controls.description.value,
      xAxisName: this.GameForm.controls.xAxisName.value,
      yAxisName: this.GameForm.controls.yAxisName.value,
      showNumbers: this.GameForm.controls.showNumbers.value ?  this.GameForm.controls.showNumbers.value : false,
      numberOfSquares: 100,
      numbers: [],
      team: this.team,
      numberOfAvailableSquares: 100,
      globalGameIdentifier: null,
      numberOfTeamSquares: this.GameForm.controls.numberOfTeamSquares.value
    };

   
    this.dialogRef.close(game);
  }
  showNumbersChanged = (matCheckboxChange: MatCheckboxChange) => {
    this.data.showNumbers = matCheckboxChange.checked;
  }

}
export class GameDetailDialogData {
  name: string;
  description: string;
  xAxisName: string;
  yAxisName: string;
  showNumbers: boolean;
  globalGameIdentifier: string;
  numberOfTeamSquares: number;
}
