import { Action } from '@ngrx/store';
import { GameModel } from 'src/app/shared/models/GameModel';
 


export enum GameListActionTypes {
 
  GamesLoadStart = '[Games] Games Load Start',
  GamesLoadSuccess = '[Games] Games Load Success',
  GamesLoadFail = '[Games] Games Load Fail',

}
 
export class GamesLoadStart implements Action {
  readonly type = GameListActionTypes.GamesLoadStart;
  constructor(public payload: string) { }
}
export class GamesLoadSuccess implements Action {
  readonly type = GameListActionTypes.GamesLoadSuccess;

  constructor(public payload: GameModel[]) { }
}

export class GamesLoadFail implements Action {
  readonly type = GameListActionTypes.GamesLoadFail;

  constructor(public payload: string) { }
}
export type GameListActions = GamesLoadStart | GamesLoadSuccess | GamesLoadFail;

 
