import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { map, switchMap, catchError, tap, filter } from 'rxjs/operators';
import * as gameResultsActions from './game-results.actions';
import { Action } from '@ngrx/store';
import { ScoreModel } from 'src/app/shared/models/ScoreModel';
import { GameResultsHttpService } from 'src/app/shared/services/game-results-http.service';
import * as fromSquareActions from '../../squares/state/squares.actions';
 

@Injectable()
export class GameResultsEffects {

  constructor(
    private actions$: Actions,
    private gameResultsHttpService: GameResultsHttpService) { }


  @Effect()
  loadGameResultsByGameId$: Observable<Action> = this.actions$.pipe(
    ofType(gameResultsActions.GameResultsActionTypes.GameResultsLoadStart),
    map((action: gameResultsActions.GameResultsLoadStart) => action.payload),
    switchMap((gameId: string) =>
     this.gameResultsHttpService.getScoresByGameId(gameId)
    .pipe(
        map(data => (new gameResultsActions.GameResultsLoadSuccess(data))),
        catchError(err => of(new gameResultsActions.GameResultsLoadFail(err)))
      )
     )
  );

  @Effect()
  addNewGameResults: Observable<Action> = this.actions$.pipe(
    ofType(gameResultsActions.GameResultsActionTypes.GameResultsAddStart),
    map((action: gameResultsActions.GameResultsAddStart) => action.payload),
    switchMap((model: ScoreModel) =>
     this.gameResultsHttpService.newScore(model)
    .pipe(
        switchMap((data) => [
                  new gameResultsActions.GameResultsAddSuccess(data),
                  new fromSquareActions.LoadSquareDetailsStart(data.globalGameIdentifier)
                ]),
        catchError(err => of(new gameResultsActions.GameResultsAddFail(err)))
      )
     )
  );

  @Effect()
  updateGameResults: Observable<Action> = this.actions$.pipe(
    ofType(gameResultsActions.GameResultsActionTypes.GameResultsUpdateStart),
    map((action: gameResultsActions.GameResultsUpdateStart) => action.payload),
    switchMap((model: ScoreModel) =>
     this.gameResultsHttpService.updateScore(model)
    .pipe(
        switchMap((data) => [
             new gameResultsActions.GameResultsUpdateSuccess(data),
             new gameResultsActions.GameResultsLoadStart(data.globalGameIdentifier),
             new fromSquareActions.LoadSquareDetailsStart(data.globalGameIdentifier)
          ]),
        catchError(err => of(new gameResultsActions.GameResultsUpdateFail(err)))
      )
     )
  );
  @Effect()
  deleteGameResult: Observable<Action> = this.actions$.pipe(
    ofType(gameResultsActions.GameResultsActionTypes.GameResultsDeleteStart),
    map((action: gameResultsActions.GameResultsDeleteStart) => action.payload),
    switchMap((id: number) =>
     this.gameResultsHttpService.deleteScore(id)
    .pipe(
        switchMap((data) => [
             new gameResultsActions.GameResultsDeleteSuccess(data),
             new gameResultsActions.GameResultsLoadStart(data.globalGameIdentifier),
             new fromSquareActions.LoadSquareDetailsStart(data.globalGameIdentifier)
          ]),
        catchError(err => of(new gameResultsActions.GameResultsDeleteFail(err)))
      )
     )
  );
}
