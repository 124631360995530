import { GameModel } from 'src/app/shared/models/GameModel';
import * as gameListActions from './game-list.actions';
import * as fromRoot from '../../shared/state/shared.reducer';

export interface State  { 
  isLoading: boolean;
  error: string;
  games: GameModel[];
}

export const initialState: State = {
  isLoading: false,
  error: '',
  games: null
};

export function reducer(
  state = initialState,
  action: gameListActions.GamesLoadStart
          | gameListActions.GamesLoadSuccess | gameListActions.GamesLoadFail
): State {
  switch (action.type) {
    case gameListActions.GameListActionTypes.GamesLoadStart:
      return {
        ...state, isLoading: true
      };
    case gameListActions.GameListActionTypes.GamesLoadSuccess:
      return {
        ...state,
        games: action.payload, isLoading: false
      };
    case gameListActions.GameListActionTypes.GamesLoadFail:
      return {
        ...state,
        error: action.payload, isLoading: false
      };
 default:
    return state;
  }
}
