import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { map, switchMap, catchError, tap, filter } from 'rxjs/operators';
import * as gameConfigurationActions from './game-configuration.actions';
import * as gameListActions from '../../game-list/state/game-list.actions';
import { Action } from '@ngrx/store';
import { GameConfigurationHttpService } from 'src/app/shared/services/game-configuration-http.service';
import { OktaAuthService } from '@okta/okta-angular';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { SetToastError, SetToastConfirmation } from '../../shared/state/shared.actions';
@Injectable()
export class GameConfigurationEffects {

  globalTeamId: string = '';
  constructor(
    private actions$: Actions,
    private gameConfigHttpService: GameConfigurationHttpService,
    public sharedStateService: SharedStateService
  ) {

    this.sharedStateService.user$.subscribe((user) => {
      if (user) {
        this.globalTeamId = user.globalTeamId;
      }
    });
  }


  @Effect()
  autoGenterateNumbers$: Observable<Action> = this.actions$.pipe(
    ofType(gameConfigurationActions.GameConfigurationActionTypes.AutoGenerateNumbersStart),
    switchMap((action: gameConfigurationActions.AutoGenerateNumbersStart) => {
      return this.gameConfigHttpService.generateRandomNumbers(action.payload).pipe(
        switchMap(data => ([
          new gameConfigurationActions.AutoGenerateNumbersSuccess(data),
          new SetToastConfirmation({ content: 'Numbers have been successfully generated!', duration: 4000 }),
          new gameListActions.GamesLoadStart(this.globalTeamId)
        ])),
        catchError(err => of(new gameConfigurationActions.AutoGenerateNumbersFail(err),
        new SetToastError('Failed to generate numbers')))
      );
    })
  );
  @Effect()
  randomlyAssignSellers$: Observable<Action> = this.actions$.pipe(
    ofType(gameConfigurationActions.GameConfigurationActionTypes.RandomlyAssignSellersStart),
    switchMap((action: gameConfigurationActions.RandomlyAssignSellersStart) => {
      return this.gameConfigHttpService.randomlyAssignSellers(action.payload).pipe(
        switchMap(data => ([
          new gameConfigurationActions.RandomlyAssignSellersSuccess(data),
          new SetToastConfirmation({ content: 'Sellers have been successfully been assigned!', duration: 4000 }),
          new gameListActions.GamesLoadStart(this.globalTeamId)
        ])),
        catchError(err => of(new gameConfigurationActions.RandomlyAssignSellersFail(err),
          new SetToastError('Failed to assign sellers')))
      );
    })
  );
}
