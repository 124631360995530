import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from './../../shared/state/shared.reducer';
import * as fromShared from '../state/shared.reducer';
import { FeatureNames } from '../constants';
import { UserModel } from '../models/userModel';

export interface State extends fromRoot.State {
  Shared: fromShared.State;
}

const getSharedFeatureState = createFeatureSelector<fromShared.State>(
  FeatureNames.Shared
);

export const getUser = createSelector(
  getSharedFeatureState,
  state => state.user
);

 
export const getToastState = createSelector(
  getSharedFeatureState,
  state => state.toasts
);

 
export const isSpinnerShowing = createSelector(
  getSharedFeatureState,
  state => state.showSpinner
);
 