import { NgModule } from '@angular/core';
import { MatTableModule, MatSortModule, MatMenuModule,
  MatButtonModule, MatSelectModule, MatOptionModule,
  MatCheckboxModule, MatFormFieldModule, MatInputModule,
  MatDatepickerModule, MAT_DATE_FORMATS, MatIconModule,
  MatNativeDateModule,
  MatExpansionModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatProgressSpinnerModule
  } from '@angular/material';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MdePopoverModule } from '@material-extended/mde';
import { HttpClientModule } from '@angular/common/http';

export const FCMA_DATE_FORMAT = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'LL',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'LL',
  }
};

@NgModule({
  imports: [
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MomentDateModule,
    MdePopoverModule,
    MatSelectModule,
    MatOptionModule,
    MatExpansionModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    HttpClientModule
  ],
  exports: [
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MomentDateModule,
    MdePopoverModule,
    MatExpansionModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: FCMA_DATE_FORMAT }
  ]
})
export class MaterialModule { }
