import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store, select } from '@ngrx/store';
import { ScoreModel } from 'src/app/shared/models/ScoreModel';
import * as fromSeller from '../../state/seller.reducer';
import * as fromAction from '../../state/seller.actions';
import { SellerModel } from 'src/app/shared/models/SellerModel';
import * as fromIndex from '../../state/index';
import { SellerModalComponent } from '../seller-modal/seller-modal.component';
import { SellerService } from '../../services/seller.service';

@Component({
  selector: 'app-seller-modal-grid',
  templateUrl: './seller-modal-grid.component.html',
  styleUrls: ['./seller-modal-grid.component.scss']
})
export class SellerModalGridComponent implements OnInit {
  sellers: SellerModel[];
  displayedColumns: string[] = ['index', 'firstName', 'lastName', 'emailAddress', 'isTeamSeller', 'isActive','action'];
 
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<SellerModalGridComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public sellerService: SellerService, public store: Store<fromSeller.State>) { }

  ngOnInit() {
    this.store.dispatch(new fromAction.SellerLoadStart(this.data.globalTeamId));
    this.store.pipe(select(fromIndex.getSellers)).subscribe(sellers => {
      if (sellers) {
        this.sellers = sellers;
  
      }
    });
  }
  openDialog(action: string, s: SellerModel) {
    this.openDialogRef(action, s);
  }
  openDialogRef(action: string, seller: SellerModel): void {
    const dialogRef = this.dialog.open(SellerModalComponent, {
      width: '500px',
      data: { info: this.data, seller, action }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.isDelete){
        this.sellerService.deleteScore(result.seller.sellerId);
      } else {
        this.sellerService.upsertScore(result.seller);
      }
    });
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
