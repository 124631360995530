import { Action } from '@ngrx/store';
import { ScoreModel } from 'src/app/shared/models/ScoreModel';

export enum GameResultsActionTypes {
  GameResultsLoadStart = '[GameResults] GameResults Load Start',
  GameResultsLoadSuccess = '[GameResults] GameResults Load Success',
  GameResultsLoadFail = '[GameResults] GameResults Load Fail',
  GameResultsAddStart = '[GameResults] GameResults Add Start',
  GameResultsAddSuccess = '[GameResults] GameResults Add Success',
  GameResultsAddFail = '[GameResults] GameResults Add Fail',
  GameResultsUpdateStart = '[GameResults] GameResults Update Start',
  GameResultsUpdateSuccess = '[GameResults] GameResults Update Success',
  GameResultsUpdateFail = '[GameResults] GameResults Update Fail',
  GameResultsDeleteStart = '[GameResults] GameResults Delete Start',
  GameResultsDeleteSuccess = '[GameResults] GameResults Delete Success',
  GameResultsDeleteFail = '[GameResults] GameResults Delete Fail',
}
 
export class GameResultsLoadStart implements Action {
  readonly type = GameResultsActionTypes.GameResultsLoadStart;
  constructor(public payload: string) { }
}
export class GameResultsLoadSuccess implements Action {
  readonly type = GameResultsActionTypes.GameResultsLoadSuccess;

  constructor(public payload: ScoreModel[]) { }
}

export class GameResultsLoadFail implements Action {
  readonly type = GameResultsActionTypes.GameResultsLoadFail;

  constructor(public payload: string) { }
}
export class GameResultsAddStart implements Action {
  readonly type = GameResultsActionTypes.GameResultsAddStart;
  constructor(public payload: ScoreModel) { }
}
export class GameResultsAddSuccess implements Action {
  readonly type = GameResultsActionTypes.GameResultsAddSuccess;
  constructor(public payload: ScoreModel) { }
}

export class GameResultsAddFail implements Action {
  readonly type = GameResultsActionTypes.GameResultsAddFail;

  constructor(public payload: string) { }
}
export class GameResultsUpdateStart implements Action {
  readonly type = GameResultsActionTypes.GameResultsUpdateStart;
  constructor(public payload: ScoreModel) { }
}
export class GameResultsUpdateSuccess implements Action {
  readonly type = GameResultsActionTypes.GameResultsUpdateSuccess;

  constructor(public payload: ScoreModel) { }
}

export class GameResultsUpdateFail implements Action {
  readonly type = GameResultsActionTypes.GameResultsUpdateFail;

  constructor(public payload: string) { }
}
export class GameResultsDeleteStart implements Action {
  readonly type = GameResultsActionTypes.GameResultsDeleteStart;
  constructor(public payload: number) { }
}
export class GameResultsDeleteSuccess implements Action {
  readonly type = GameResultsActionTypes.GameResultsDeleteSuccess;

  constructor(public payload: ScoreModel) { }
}

export class GameResultsDeleteFail implements Action {
  readonly type = GameResultsActionTypes.GameResultsDeleteFail;

  constructor(public payload: string) { }
}
export type GameResultsActions = GameResultsLoadStart | GameResultsLoadSuccess | GameResultsLoadFail
                             | GameResultsAddStart | GameResultsAddSuccess | GameResultsAddFail
                              | GameResultsUpdateStart | GameResultsUpdateSuccess | GameResultsUpdateFail
                              | GameResultsDeleteStart | GameResultsDeleteSuccess | GameResultsDeleteFail;
