import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, InjectionToken, Injector } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './shared/state/shared.reducer';
import { SharedModule } from './shared/modules/shared.module';
import { AppComponent } from './app.component';
import { AppHeaderComponent } from './shared/components/app-header/app-header.component';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './shared/modules/material.module';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GameResultsModule } from './game-results/game-results.module';
import { ExportToPngModalComponent } from './shared/components/export-to-png-modal/export-to-png-modal.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './shared/security/auth.interceptor';
import { OktaAuthModule, OKTA_CONFIG, OktaCallbackComponent, OktaAuthService, OktaAuthGuard } from '@okta/okta-angular';
import { GameModule } from './game/game.module';
import { GameConfigurationModule } from './game-configuration/game-configuration.module';
import { UserInfoService } from './shared/services/user-info.service';
 
import { GameListModule } from './game-list/game-list.module';
import { EditGameModule } from './edit-game/edit-game.module';
import { PageSpinnerComponent } from './shared/components/page-spinner/page-spinner.component';
import { AppSettingsService } from './shared/services/appSettings.service';

import {  OktaConfigProvider } from './shared/security/okta-auth.config';
import { PreInitService } from './shared/services/pre-init.service';
 
import { SellerModalGridComponent } from './seller/components/seller-modal-grid/seller-modal-grid.component';
import { SellerModalComponent } from './seller/components/seller-modal/seller-modal.component';
import { SellerModule } from './seller/seller.module';
 

export function preInitServiceFactory(initService: PreInitService)
 {
  return () =>  initService.getSettings();
}
 
@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    ExportToPngModalComponent,
 
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    SharedModule,
    GameListModule,
    GameResultsModule,
    SellerModule,
    GameConfigurationModule,
    GameModule,
    EditGameModule,
    StoreModule.forRoot({shared: reducer}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'Square Game',
      maxAge: 25,
      logOnly: environment.production
    }),
    OktaAuthModule,
  ],
    providers: [
      OktaAuthGuard,
      UserInfoService,
      {
        provide: APP_INITIALIZER,
        useFactory: preInitServiceFactory,
        deps: [AppSettingsService],
        multi: true
      },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      PreInitService,
      {
        provide: APP_INITIALIZER,
        useFactory: preInitServiceFactory,
        deps: [PreInitService, Injector],
        multi: true,
      },
      OktaConfigProvider,
      OktaAuthService
       ],
  bootstrap: [AppComponent]
})
export class AppModule { }
