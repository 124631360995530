import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GameModel } from 'src/app/shared/models/GameModel';
import { SquareHttpService } from 'src/app/shared/services/square-http.service';
import { GameService } from 'src/app/shared/services/game.service';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { MatDialog } from '@angular/material';

import { GameDetailModalComponent, GameDetailDialogData } from 'src/app/shared/components/game-detail-modal/game-detail-modal.component';


@Component({
  selector: 'app-game-list-actions',
  templateUrl: './game-list-actions.component.html',
  styleUrls: ['./game-list-actions.component.scss']
})
export class GameListActionsComponent implements OnInit {

  constructor(private router: Router, private squareHttpService: SquareHttpService,
    private gameService: GameService, private sharedStateService: SharedStateService,
    public dialog: MatDialog) { }
  @Input() game: GameModel;
   selectedGame: GameModel;
  showAssignSellersLink: boolean = false;
  showGenerateNumbersLink: boolean = false;
  ngOnInit() {
    this.selectedGame = this.game;
    this.squareHttpService.areSellersAssigned(this.game.globalGameIdentifier).subscribe(x => {
      if (x != null) {
        this.showAssignSellersLink = !x;
      }
    });

    this.showGenerateNumbersLink = this.game.numbers
      .filter(x => x.globalGameIdentifier === this.game.globalGameIdentifier)
      .every(x => x.xAxisValue === null);
  }
  gotoSquaresDetail(id: number) {
    this.router.navigate([`/squares/${id}`]);
  }



  generateRandomNumbers(globalGameId: string) {
    this.gameService.generateRandomNumbers(globalGameId);
  }

  randomlyAssignSellers(globalGameId: string) {
    this.sharedStateService.user$.subscribe(user => {
      if (user) {
        this.gameService.randomlyAssignSellers(globalGameId, user.globalTeamId);
      }
    });
  }


}

