import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 
import { EffectsModule } from '@ngrx/effects';
import { MaterialModule } from '../shared/modules/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
 
import { StoreModule } from '@ngrx/store';
import { FeatureNames } from '../shared/constants';
import { reducer } from './state/seller.reducer';
import { SellerModalGridComponent } from './components/seller-modal-grid/seller-modal-grid.component';
import { SellerModalComponent } from './components/seller-modal/seller-modal.component';
import { SellerComponent } from './container/seller.component';
import { SellerRoutingModule } from './seller-routing.module';
import { SellerEffects } from './state/seller.effects';
import { SellerService } from './services/seller.service';
 
 

@NgModule({
  imports: [
    CommonModule,
    SellerRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    StoreModule.forFeature(FeatureNames.Sellers, reducer),
    EffectsModule.forFeature([SellerEffects]),
  ],
  declarations: [
   SellerComponent,
   SellerModalComponent,
   SellerModalGridComponent

  ],
  exports: [
    ReactiveFormsModule,
   SellerComponent
  ],
  providers: [SellerService],
  entryComponents: [
   SellerModalComponent,
   SellerModalGridComponent
 ]
})
export class SellerModule { }
