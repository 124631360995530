import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameResultsRoutingModule } from './game-results-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { MaterialModule } from '../shared/modules/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GameResultsModalComponent } from './components/game-results-modal/game-results-modal.component';
import { GameResultsComponent } from './container/game-results.component';
import { GameResultsModalGridComponent } from './components/game-results-modal-grid/game-results-modal-grid.component';
import { StoreModule } from '@ngrx/store';
import { FeatureNames } from '../shared/constants';
import { reducer } from './state/game-results.reducer';
import { GameResultsEffects } from './state/game-results.effects';
import { GameResultsService } from './services/game-results.service';

@NgModule({
  imports: [
    CommonModule,
    GameResultsRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    StoreModule.forFeature(FeatureNames.GameResults, reducer),
    EffectsModule.forFeature([GameResultsEffects]),
  ],
  declarations: [
    GameResultsComponent,
    GameResultsModalComponent,
    GameResultsModalGridComponent

  ],
  exports: [
    ReactiveFormsModule,
    GameResultsComponent
  ],
  providers: [GameResultsService],
  entryComponents: [
    GameResultsModalComponent,
    GameResultsModalGridComponent
 ]
})
export class GameResultsModule { }
