import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSeller from '../state/seller.reducer';
import { FeatureNames } from 'src/app/shared/constants';

export interface State   {
  sellers: fromSeller.State;
}

const getSellersFeatureState = createFeatureSelector<fromSeller.State>(FeatureNames.Sellers);

export const getSellers = createSelector(
  getSellersFeatureState,
  state => state != null ? state.sellers : null);
 