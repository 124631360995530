import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { GameModel } from 'src/app/shared/models/GameModel';
 
@Component({
  selector: 'app-game-grid',
  templateUrl: './game-grid.component.html',
  styleUrls: ['./game-grid.component.scss']
})
export class GameGridComponent implements OnInit {
  @Input() gameList: GameModel[];
  constructor(private router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {

    iconRegistry.addSvgIcon(
      'more_vert',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/baseline-more_vert-24px.svg'));
   }

  ngOnInit() {
      
 }
 
}
