import { Injectable } from '@angular/core';

import { Observable, from } from 'rxjs';
import { SharedStateService } from '../services/shared-state.service';
import { filter, take, map } from 'rxjs/operators';

 
import { UserModel } from '../models/UserMode';
import { AppSettingsService } from './appSettings.service';
import { OktaAuthService } from '@okta/okta-angular';
 

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  isAuthenticated = false;

  constructor(
    private oktaAuth: OktaAuthService,
    private sharedState: SharedStateService,
    private settingsService: AppSettingsService,
  ) {

  }

 

    

  get userInfo(): Observable<UserModel> {
    return from(this.getUser());
  }

  private async getUser(): Promise<UserModel> {
    const userClaims = await this.oktaAuth.getUser();
    if (userClaims) {
      return Promise.resolve<UserModel>({
        email: userClaims.email,
        name: userClaims.userFullName,
        initials: this.initialsFromName(userClaims.name),
        globalTeamId: userClaims.globalTeamId
      });
    } else {
      return Promise.resolve<UserModel>({
        email: '',
        name: '',
        initials: '',
        globalTeamId: ''
      });
    }
  }

  private initialsFromName(name: string): string {
    if (name == null) {
      return '';
    }

    const nameTokens = name.split(' ');
    let fnInitial = '', lnInitial = '';
    if (nameTokens[0]) {
      fnInitial = nameTokens[0].slice(0, 1);
    }
    if (nameTokens[nameTokens.length - 1] && nameTokens.length > 1) {
      lnInitial = nameTokens[nameTokens.length - 1].slice(0, 1);
    }

    return `${fnInitial}${lnInitial}`;
  }



}
