import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MaterialModule } from '../shared/modules/material.module';

import { reducer } from './state/game-list.reducer';
import { GameListRoutingModule } from './game-list-routing.module';
import { GameListEffects } from './state/game-list.effects';
import { GameListService } from './services/game-list.service';
import { GameListComponent } from './container/game-list.component';
import { GameGridComponent } from './components/game-grid/game-grid.component';
import { GameListActionsComponent } from './components/game-list-actions/game-list-actions.component';
import { FeatureNames } from '../shared/constants';
import { EditGameComponent } from '../edit-game/container/edit-game.component';
 

@NgModule({
  imports: [
    CommonModule,
    GameListRoutingModule,
    StoreModule.forFeature(FeatureNames.Games, reducer),
    EffectsModule.forFeature([GameListEffects]),
    MaterialModule],
    providers: [GameListService],
  declarations: [GameListComponent, GameGridComponent, GameListActionsComponent, EditGameComponent],

 
  
 })
export class GameListModule { }
